






























import { useUiState } from '~/composables';
import {
  defineComponent, ref, computed, useFetch,
} from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import {
  SfButton, SfDropdown,
} from '@storefront-ui/vue';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import SvgImage from '~/components/General/SvgImage.vue';
import AccountDropdownContent from '~/components/TopBar/AccountDropdownContent.vue';

export default defineComponent({
  components: {
    SfButton,
    SfDropdown,
    SvgImage,
    AccountDropdownContent,
  },
  directives: { clickOutside },
  setup() {
    const { toggleLoginModal } = useUiState();
    const { isAuthenticated, user, load: userLoad } = useUser();
    const isOpen = ref(false);

    const userName = computed(() => user.value?.firstname || '');

    useFetch(async () => {
      await userLoad();
    });

    const handleDropdownClick = () => {
      if (isAuthenticated.value) {
        isOpen.value = !isOpen.value;
      } else {
        toggleLoginModal();
      }
    };

    return {
      handleDropdownClick,
      isAuthenticated,
      isOpen,
      userName,
    };
  },
});


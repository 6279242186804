import { UseContextReturn } from '~/types/core';
import pinterestOptions from '~/modules/pinterest/queries/pinterestOptions.gql';
import { PinterestOptions } from '~/modules/GraphQL/types';

export const pinterestOptionsCommand = {
  execute: async (context: UseContextReturn): Promise<PinterestOptions | null> => {
    const { data } = await context.app.$vsf.$magento.api.customQuery({ query: pinterestOptions });

    return (data as any)?.pinterestOptions ?? null;
  },
};

import generateRememberMeToken from '~/modules/customer/composables/useRememberMe/mutations/generateRememberMeToken.gql';
import { VsfContext } from '~/composables/context';

export const generateRememberMeTokenCommand = {
  execute: async (context: VsfContext): Promise<string | null> => {
    const { data, errors } = await context.$magento.api.customMutation({ mutation: generateRememberMeToken, mutationVariables: {} });

    if (errors && errors.length > 0) {
      throw new Error(errors.map(error => error.message).join(' '));
    }
    return (data as any)?.generateRememberMeToken?.token ?? null;
  },
};

import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { UsePinterestInterface, UsePinterestErrors, UsePinterestMetaTag } from '~/modules/pinterest/usePinterest';
import { pinterestOptionsCommand } from '~/modules/pinterest/commands/pinterestOptionsCommand';
import { PinterestOptions } from '~/modules/GraphQL/types';
import { parse, HTMLElement } from 'node-html-parser';
import script, { trackPage } from './script';
import useUser from '~/modules/customer/composables/useUser';

export function usePinterest(): UsePinterestInterface {
  const context = useContext();
  const loading = ref(false);
  const error = ref<UsePinterestErrors>({
    loadOptions: null,
  });
  const { user } = useUser();

  const loadOptions = async (): Promise<PinterestOptions | null> => {
    Logger.debug('usePinterest/loadOptions');

    let result = null;
    try {
      loading.value = true;

      result = await pinterestOptionsCommand.execute(context);
      Logger.debug('[Result]:', result);

      error.value.loadOptions = null;
    } catch (err) {
      error.value.loadOptions = err;
      Logger.error('usePinterest/loadOptions', err);
    } finally {
      loading.value = false;
    }

    return result;
  }

  const parseMetaTag = (tag: string): UsePinterestMetaTag | null => {
    if (!tag) {
      return null;
    }
    if (typeof document !== 'undefined') {
      const div = document.createElement('div');
      div.innerHTML = tag;
      if (!div.firstElementChild || div.firstElementChild.tagName !== 'META') {
        return null;
      }
      const name = div.firstElementChild.getAttribute('name');
      const content = div.firstElementChild.getAttribute('content');
      return {
        name,
        content,
      }
    } else {
      const root = parse(tag);
      if (!root.firstChild || !(root.firstChild instanceof HTMLElement)) {
        return null;
      }
      const element = root.firstChild as HTMLElement;
      if (element.tagName !== 'META') {
        return null;
      }
      const name = element.getAttribute('name');
      const content = element.getAttribute('content');
      return {
        name,
        content,
      }
    }
  };

  const executeScript = (config: PinterestOptions) => {
    script(config, user.value);
  };

  return {
    loadOptions,
    parseMetaTag,
    executeScript,
    trackPage,
    error: readonly(error),
    loading: readonly(loading),
  }
}

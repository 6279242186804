

















import { defineComponent, useContext } from '@nuxtjs/composition-api';
import LinkWithArrow from '~/components/General/LinkWithArrow.vue';

export default defineComponent({
  components: {
    LinkWithArrow,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      required: true,
    },
    sizes: {
      type: Object,
      default: () => ({
        desktop: [1200, 600],
        mobile: [600, 300],
      }),
    },
  },
  setup(props) {
    const { app } = useContext();
    const { isDesktop } = app.$device;

    const nuxtImgConfig = {
      loading: 'lazy',
      format: 'webp',
      width: isDesktop ? props.sizes.desktop[0] : props.sizes.mobile[0],
      height: isDesktop ? props.sizes.desktop[1] : props.sizes.mobile[1],
      placeholder: false,
    };

    return {
      nuxtImgConfig,
    };
  },
});

import { GetProductSearchParams } from '~/modules/catalog/product/types';
import { ProductList } from '~/modules/catalog/product/composables/useProduct';
import { Logger } from '~/helpers/logger';
import { getProductListCommand } from '~/modules/algolia/composables/useAlgolia/commands/getProductListCommand';
import { getAlgoliaConfigCommand } from '~/modules/algolia/composables/useAlgolia/commands/getAlgoliaConfigCommand';
import { ref, useContext, readonly, computed } from '@nuxtjs/composition-api';
import { AlgoliaConfigInterface, UseAlgoliaErrors, UseAlgoliaInterface } from '~/modules/algolia/composables/useAlgolia/useAlgolia';
import { useAlgoliaStore } from '~/modules/algolia/stores/algolia';

export function useAlgolia(): UseAlgoliaInterface {
  const algoliaStore = useAlgoliaStore();
  const algoliaEnabled = computed(() => algoliaStore.algoliaEnabled);
  const loading = ref(false);
  const error = ref<UseAlgoliaErrors>({
    getConfig: null,
    getProductList: null,
  });

  const context = useContext();

  const getConfig = async (): Promise<AlgoliaConfigInterface | null> => {
    Logger.debug('useAlgolia/getConfig');
    try {
      loading.value = true;
      return await getAlgoliaConfigCommand.execute(context);
    } catch (err) {
      error.value.getConfig = err;
      Logger.error('useAlgolia/getConfig', err);
      return null;
    } finally {
      loading.value = false;
    }
  };

  const getProductList = async (searchParams: GetProductSearchParams): Promise<ProductList | null> => {
    Logger.debug('useAlgolia/getProductList', searchParams);
    let products: ProductList = null;

    try {
      loading.value = true;
      products = await getProductListCommand.execute(context, searchParams);
      error.value.getProductList = null;
    } catch (err) {
      error.value.getProductList = err;
      Logger.error('useAlgolia/getProductList', err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  return {
    algoliaEnabled,
    getConfig,
    getProductList,
    error: readonly(error),
    loading: readonly(loading),
  };
}

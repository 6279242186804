/** GraphQL Query that fetches store configuration from the API */
export const StoreConfigQuery = `
  query storeConfig {
    storeConfig {
        store_code,
        default_title,
        store_name,
        default_display_currency_code,
        locale,
        header_logo_src,
        logo_width,
        logo_height,
        logo_alt,
        copyright,
        store_information_name
        store_information_email
        store_information_phone
        store_information_street_address
        store_information_city
        store_information_region_id
        store_information_region_code
        store_information_country_id
        store_information_postcode
        store_location_latitude
        store_location_longitude
        is_cart_comment_enabled
        home_page_features_solid_wood
        home_page_features_bunk_beds
        home_page_features_shipping
        home_page_features_in_stock
        home_page_shipping_free_shipping
        seo_social_profiles_facebook
        seo_social_profiles_twitter
    }
  }
`;

export default StoreConfigQuery;

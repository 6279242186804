import revokeRememberMeToken from '~/modules/customer/composables/useRememberMe/mutations/revokeRememberMeToken.gql';
import { VsfContext } from '~/composables/context';

export const revokeRememberMeTokenCommand = {
  execute: async (context: VsfContext, token: string): Promise<boolean | null> => {
    const { data, errors } = await context.$magento.api.customMutation({ mutation: revokeRememberMeToken, mutationVariables: { token } });

    if (errors && errors.length > 0) {
      throw new Error(errors.map(error => error.message).join(' '));
    }
    return (data as any)?.revokeRememberMeToken?.result ?? null;
  },
};

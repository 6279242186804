import { UseContextReturn } from '~/types/core';
import addProductsToGuestWishlist from '~/modules/wishlist/composables/useGuestWishlist/mutations/addProductsToGuestWishlist.gql';
import type { AddProductsToGuestWishlistMutationVariables, AddProductsToGuestWishlistOutput } from '~/modules/GraphQL/types';

export const addProductsToGuestWishlistCommand = {
  execute: async (context: UseContextReturn, input: AddProductsToGuestWishlistMutationVariables): Promise<AddProductsToGuestWishlistOutput | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.customMutation({ mutation: addProductsToGuestWishlist, mutationVariables: input });

    if (errors && errors.length > 0) {
      throw new Error(errors.map(error => error.message).join(' '));
    }
    return (data as any)?.addProductsToGuestWishlist ?? null;
  },
};

export default `
  query getAlgoliaConfig {
    algolia_config {
      enabled
      application_id
      api_key
      index_name
    }
  }
`;































import {
  defineComponent, useRouter, useContext,
} from '@nuxtjs/composition-api';
import { SfList, SfMenuItem } from '@storefront-ui/vue';
import { useSidebarLinkGroups } from '~/modules/customer/pages/MyAccount/useSidebarLinkGroups';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';

export default defineComponent({
  components: {
    SfList,
    SfMenuItem,
  },
  directives: { clickOutside },
  setup(props, { emit }) {
    const router = useRouter();
    const { app } = useContext();
    const { logoutUser } = useSidebarLinkGroups();

    const handleAccountClick = async () => {
      await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      emit('done', true);
    };

    const handleOrdersClick = async () => {
      await router.push(app.localeRoute({ name: 'customer-order-history' }));
      emit('done', true);
    };

    const handleLogoutClick = async () => {
      await logoutUser();
      emit('done', true);
    };

    return {
      handleAccountClick,
      handleOrdersClick,
      handleLogoutClick,
    };
  },
});


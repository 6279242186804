
































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  components: {
    SfButton,
    SvgImage,
  },
  props: {
    link: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      default: 'Go',
    },
  },
  setup() {
    const isHover = ref(false);

    return {
      isHover,
    };
  },
});


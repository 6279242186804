export default `
  query GetPinterestOptions {
    pinterestOptions {
      is_user_connected
      is_conversion_config_enabled
      meta_tag
      is_ldp_enabled
      is_cookie_restriction_mode_enabled
      current_website_id
      gdpr_cookie_name
      is_gdpr_enabled
      gdpr_option
      tag_id
    }
  }
`;

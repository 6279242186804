








import {
  useRoute, defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'EmptyLayout',
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
});

import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { findItemOnWishlist } from '~/modules/wishlist/helpers/findItemOnWishlist';
import { Logger } from '~/helpers/logger';
import { useGuestWishlistStore } from '~/modules/wishlist/store/guestWishlistStore';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { GuestWishlist } from '~/modules/GraphQL/types';
import type {
  UseGuestWishlistAddItemParams,
  UseGuestWishlistErrors,
  UseGuestWishlistInterface,
  UseGuestWishlistIsInWishlistParams,
  UseGuestWishlistLoadParams,
  UseGuestWishlistRemoveItemParams,
  UseGuestWishlistAfterAddingWishlistItemToCartParams,
  UseGuestWishlistSendParams
} from '~/modules/wishlist/composables/useGuestWishlist/useGuestWishlist';
import { useUiNotification } from '~/composables';
import { guestWishlistCommand } from '~/modules/wishlist/composables/useGuestWishlist/commands/guestWishlistCommand';
import { createGuestWishlistCommand } from '~/modules/wishlist/composables/useGuestWishlist/commands/createGuestWIshlistCommand';
import { addProductsToGuestWishlistCommand } from '~/modules/wishlist/composables/useGuestWishlist/commands/addProductsToGuestWishlistCommand';
import { removeProductsFromGuestWishlistCommand } from '~/modules/wishlist/composables/useGuestWishlist/commands/removeProductsFromGuestWishlistCommand';
import { guestWishlistItemsCountCommand } from '~/modules/wishlist/composables/useGuestWishlist/commands/guestWishlistItemsCountCommand';
import { mergeWishlistWithGuestWishlistCommand } from '~/modules/wishlist/composables/useGuestWishlist/commands/mergeWishlistWithGuestWishlistCommand';
import { sendGuestWishlistCommand } from '~/modules/wishlist/composables/useGuestWishlist/commands/sendGuestWishlistCommand';

const COOKIE_GUEST_CUSTOMER_ID = 'vsf-guest-customer-id';
const DEFAULT_PAGE_SIZE = 1000000;

/**
 * Allows loading and manipulating wishlist of the current user.
 *
 * See the {@link UseGuestWishlistInterface} for a list of methods and values available in this composable.
 */
export function useGuestWishlist(): UseGuestWishlistInterface {
  const guestWishlistStore = useGuestWishlistStore();
  const wishlistStore = useWishlistStore();
  const context = useContext();
  const { app } = context;
  const { send: sendNotification } = useUiNotification();
  const loading = ref(false);
  const error = ref<UseGuestWishlistErrors>({
    create: null,
    addItem: null,
    removeItem: null,
    load: null,
    clear: null,
    loadItemsCount: null,
    afterAddingWishlistItemToCart: null,
    send: null,
  });

  const create = async () => {
    Logger.debug('useGuestWishlist/create');

    try {
      loading.value = true;
      Logger.debug('[Magento Storefront]: useGuestWishlist.create');

      guestWishlistStore.wishlist = await createGuestWishlistCommand.execute(context);
      context.$cookies.set(COOKIE_GUEST_CUSTOMER_ID, guestWishlistStore.wishlist.guest_customer_id);
      Logger.debug('[Result]:', guestWishlistStore.wishlist);

      error.value.create = null;
    } catch (err) {
      error.value.create = err;
      Logger.error('useGuestWishlist/create', err);
    } finally {
      loading.value = false;
    }

    return guestWishlistStore.wishlist;
  }

  const load = async (params?: UseGuestWishlistLoadParams) => {
    Logger.debug('useGuestWishlist/load');

    let loaded = false;
    try {
      loading.value = true;
      Logger.debug('[Magento Storefront]: useGuestWishlist.load params->', params);

      const guestCustomerId = context.$cookies.get(COOKIE_GUEST_CUSTOMER_ID);
      if (guestCustomerId) {
        guestWishlistStore.wishlist = await guestWishlistCommand.execute(context, {
          guestCustomerId,
          itemsCurrentPage: params?.searchParams?.currentPage,
          itemsPageSize: params?.searchParams?.pageSize,
        });
        loaded = true;
        Logger.debug('[Result]:', guestWishlistStore.wishlist);
      }
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useGuestWishlist/load', err);
    } finally {
      loading.value = false;
    }

    if (!loaded) {
      return create();
    }

    return guestWishlistStore.wishlist;
  };

  const loadWithAllItems = async () => {
    Logger.debug('useGuestWishlist/loadWithAllItems');

    try {
      const wishlist = await load({
        searchParams: {
          pageSize: DEFAULT_PAGE_SIZE,
          currentPage: 1
        }
      });
      if (wishlist.items_count > DEFAULT_PAGE_SIZE) {
        await load({
          searchParams: {
            pageSize: wishlist.items_count,
            currentPage: 1
          }
        });
      }
    } catch (err) {
      Logger.error('useGuestWishlist/loadWithAllItems', err);
    }

    return guestWishlistStore.wishlist;
  };

  const isInWishlist = ({ product }: UseGuestWishlistIsInWishlistParams) => {
    Logger.debug('useGuestWishlist/isInWishlist', product);

    const wishlistProduct = findItemOnWishlist(guestWishlistStore.wishlist, product);

    return !!(wishlistProduct?.id && wishlistProduct?.quantity);
  };

  const setWishlist = (newWishlist: GuestWishlist) => {
    guestWishlistStore.wishlist = newWishlist;
    Logger.debug('useGuestWishlist/setWishlist', newWishlist);
  };

  const removeItem = async ({ product, customQuery, customHeaders }: UseGuestWishlistRemoveItemParams) => {
    Logger.debug('useGuestWishlist/removeItem', product);

    try {
      loading.value = true;
      Logger.debug('[Magento Storefront]: useGuestWishlist.removeItem params->', {
        currentWishlist: guestWishlistStore.wishlist,
        product,
        customQuery,
        customHeaders,
      });

      const guestCustomerId = context.$cookies.get(COOKIE_GUEST_CUSTOMER_ID);
      if (!guestCustomerId) {
        Logger.error('Guest wishlist is not created');
        return;
      }

      const itemOnWishlist = findItemOnWishlist(guestWishlistStore.wishlist, product);
      const result = await removeProductsFromGuestWishlistCommand.execute(context, {
        guestCustomerId,
        items: [itemOnWishlist.id],
        itemsPageSize: guestWishlistStore?.wishlist?.items_count > DEFAULT_PAGE_SIZE ? guestWishlistStore.wishlist.items_count + 10 : DEFAULT_PAGE_SIZE,
      });

      Logger.debug('[Result]:', result);
      error.value.removeItem = null;
      guestWishlistStore.$patch((state) => {
        state.wishlist = result?.wishlist ?? {};
      });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useGuestWishlist/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const loadItemsCount = async (): Promise<number | null> => {
    Logger.debug('useGuestWishlist/loadItemsCount');
    let itemsCount : number | null = null;

    try {
      loading.value = true;
      error.value.loadItemsCount = null;
      const guestCustomerId = context.$cookies.get(COOKIE_GUEST_CUSTOMER_ID);
      if (guestCustomerId) {
        const wishlist = await guestWishlistItemsCountCommand.execute(context, guestCustomerId);

        Logger.debug('[Result]:', wishlist);
        itemsCount = wishlist?.items_count || 0;
        guestWishlistStore.$patch((state) => {
          state.wishlist.items_count = itemsCount;
        });
      }
    } catch (err) {
      error.value.loadItemsCount = err;
      Logger.error('useGuestWishlist/loadItemsCount', err);
    } finally {
      loading.value = false;
    }

    return itemsCount;
  };

  // eslint-disable-next-line consistent-return
  const addItem = async ({ product, customQuery, customHeaders }: UseGuestWishlistAddItemParams) => {
    Logger.debug('useGuestWishlist/addItem', product);

    try {
      loading.value = true;
      Logger.debug('[Magento Storefront]: useGuestWishlist.addItem params->', {
        currentWishlist: guestWishlistStore.wishlist,
        product,
        customQuery,
        customHeaders,
      });

      if (!guestWishlistStore.wishlist) {
        await load({});
      }

      const itemOnWishlist = findItemOnWishlist(guestWishlistStore.wishlist, product);

      if (itemOnWishlist) {
        return;
      }

      const guestCustomerId = context.$cookies.get(COOKIE_GUEST_CUSTOMER_ID);
      if (!guestCustomerId) {
        Logger.error('Guest wishlist is not created');
        return;
      }

      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      switch (product.__typename) {
        case 'VirtualProduct':
        case 'DownloadableProduct':
        case 'GroupedProduct':
        case 'GiftCard':
        case 'SimpleProduct': {
          const result = await addProductsToGuestWishlistCommand.execute(context, {
            guestCustomerId,
            items: [{
              sku: product.sku,
              quantity: 1,
            }],
            itemsPageSize: guestWishlistStore?.wishlist?.items_count > DEFAULT_PAGE_SIZE ? guestWishlistStore.wishlist.items_count + 10 : DEFAULT_PAGE_SIZE,
          });

          Logger.debug('[Result]:', result);

          guestWishlistStore.$patch((state) => {
            state.wishlist = result?.wishlist ?? {};
          });

          break;
        }
        case 'ConfigurableProduct': {
          const result = await addProductsToGuestWishlistCommand.execute(context, {
            guestCustomerId,
            items: [{
              sku: product.configurable_product_options_selection?.variant?.sku || product.sku,
              quantity: 1,
              parent_sku: product.sku,
            }],
            itemsPageSize: guestWishlistStore?.wishlist?.items_count > DEFAULT_PAGE_SIZE ? guestWishlistStore.wishlist.items_count + 10 : DEFAULT_PAGE_SIZE,
          });

          Logger.debug('[Result]:', result);

          guestWishlistStore.$patch((state) => {
            state.wishlist = result?.wishlist ?? {};
          });

          break;
        }
        case 'BundleProduct': {
          const result = await addProductsToGuestWishlistCommand.execute(context, {
            guestCustomerId,
            items: [{
              sku: product.sku,
              quantity: 1,
              entered_options: [],
            }],
            itemsPageSize: guestWishlistStore?.wishlist?.items_count > DEFAULT_PAGE_SIZE ? guestWishlistStore.wishlist.items_count + 10 : DEFAULT_PAGE_SIZE,
          });

          Logger.debug('[Result]:', result);

          guestWishlistStore.$patch((state) => {
            state.wishlist = result?.wishlist ?? {};
          });

          break;
        }
        default:
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          Logger.error(`Product Type ${product.__typename} not supported in add to wishlist yet`);
      }
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useGuestWishlist/addItem', err);
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const clear = async () => {
    Logger.debug('useGuestWishlist/clear');

    try {
      loading.value = true;
      error.value.clear = null;
      guestWishlistStore.$patch((state) => {
        state.wishlist = {};
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useGuestWishlist/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const afterAddingWishlistItemToCart = (
    { product, cartError }: UseGuestWishlistAfterAddingWishlistItemToCartParams,
  ) => {
    Logger.debug('useGuestWishlist/afterAddingItemToCart', product);

    if (!isInWishlist({ product })) return;

    try {
      if (cartError?.message) {
        sendNotification({
          id: Symbol('product_added_to_cart_from_wishlist_error'),
          message: cartError.message,
          type: 'danger',
          icon: 'cross',
          persist: false,
          title: 'GuestWishlist error',
        });
      } else {
        // eslint-disable-next-line promise/catch-or-return
        removeItem({ product })
          // eslint-disable-next-line promise/always-return
          .then(() => {
            sendNotification({
              id: Symbol('product_added_to_cart_from_wishlist'),
              message: app.i18n.t(
                'You added {product} to your shopping cart.',
                { product: product.name },
              ) as string,
              type: 'success',
              icon: 'check',
              persist: false,
              title: 'GuestWishlist',
            });
          });
      }
    } catch (err) {
      error.value.afterAddingWishlistItemToCart = err;
      Logger.error('useGuestWishlist/afterAddingWishlistItemToCart', err);
    }
  };

  const addOrRemoveItem = async ({ product, customQuery, customHeaders }: UseGuestWishlistAddItemParams) => {
    await (isInWishlist({ product }) ? removeItem({ product, customQuery, customHeaders }) : addItem({ product, customQuery, customHeaders }));
  };

  const mergeWishlist = async (resetOnSucess: boolean = false) => {
    Logger.debug('useGuestWishlist/mergeWishlist');

    try {
      loading.value = true;
      Logger.debug('[Magento Storefront]: useGuestWishlist.mergeWishlist');

      const guestCustomerId = context.$cookies.get(COOKIE_GUEST_CUSTOMER_ID);
      if (!guestCustomerId) {
        Logger.error('Guest wishlist is not created');
        return;
      }
      const result = await mergeWishlistWithGuestWishlistCommand.execute(context, guestCustomerId);
      Logger.debug('[Result]:', result);
      if (result) {
        wishlistStore.wishlist = result;
        if (resetOnSucess) {
          reset();
        }
      }
      error.value.load = null;
      return wishlistStore.wishlist;
    } catch (err) {
      error.value.load = err;
      Logger.error('useGuestWishlist/mergeWishlist', err);
    } finally {
      loading.value = false;
    }

    return null;
  };

  const reset = () => {
    Logger.debug('useGuestWishlist/reset');
    context.$cookies.remove(COOKIE_GUEST_CUSTOMER_ID);
    guestWishlistStore.wishlist = { items_count: 0 };
  }

  const send = async (params: UseGuestWishlistSendParams) => {
    Logger.debug('useGuestWishlist/send', params);

    const guestCustomerId = context.$cookies.get(COOKIE_GUEST_CUSTOMER_ID);
    if (!guestCustomerId) {
      Logger.error('Guest wishlist is not created');
      return;
    }

    try {
      loading.value = true;
      error.value.send = null;
      const result = await sendGuestWishlistCommand.execute(context, {
        guestCustomerId,
        name: params.name,
        email: params.email,
        emails: params.emails,
        message: params.message,
      });
      if (result?.errors?.length > 0) {
        Logger.error('useGuestWishlist/send', result.errors);
        sendNotification({
          id: Symbol('send_wishlist_error'),
          message: result.errors[0],
          type: 'danger',
          icon: 'cross',
          persist: false,
          title: 'Send wishlist error',
        });
      }
      return result;
    } catch (err) {
      error.value.send = err;
      Logger.error('useGuestWishlist/send', err);
      sendNotification({
        id: Symbol('send_wishlist_error'),
        message: err.message,
        type: 'danger',
        icon: 'cross',
        persist: false,
        title: 'Send wishlist error',
      });
    } finally {
      loading.value = false;
    }
    return null;
  }

  return {
    create,
    loadItemsCount,
    isInWishlist,
    addItem,
    load,
    loadWithAllItems,
    removeItem,
    clear,
    setWishlist,
    afterAddingWishlistItemToCart,
    addOrRemoveItem,
    mergeWishlist,
    reset,
    send,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useGuestWishlist;
export * from './useGuestWishlist';

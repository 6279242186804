import { UseContextReturn } from '~/types/core';
import sendGuestWishlist from '~/modules/wishlist/composables/useGuestWishlist/mutations/sendGuestWishlist.gql';
import type { SendGuestWishlistInput, SendGuestWishlistOutput } from '~/modules/GraphQL/types';

export const sendGuestWishlistCommand = {
  execute: async (context: UseContextReturn, queryVariables: SendGuestWishlistInput): Promise<SendGuestWishlistOutput | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.customQuery({ query: sendGuestWishlist, queryVariables });

    if (errors && errors.length > 0) {
      throw new Error(errors.map(error => error.message).join(' '));
    }
    return (data as any)?.sendGuestWishlist ?? null;
  },
};

import generateCustomerTokenByRememberMeToken from '~/modules/customer/composables/useRememberMe/mutations/generateCustomerTokenByRememberMeToken.gql';
import { FetchResult } from '@apollo/client/core';
import type { GenerateCustomerTokenByRememberMeToken } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';

export interface GenerateCustomerTokenByRememberMeTokenCommandParams {
  token: string;
  recaptchaToken?: string | null | undefined;
}

export const generateCustomerTokenByRememberMeTokenCommand = {
  execute: async (context: VsfContext, params): Promise<FetchResult<GenerateCustomerTokenByRememberMeToken>> => {
    return await context.$magento.api.customMutation({ mutation: generateCustomerTokenByRememberMeToken, mutationVariables: params });
  },
};

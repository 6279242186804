import { Context as NuxtContext } from '@nuxt/types';
import { merge } from 'lodash-es';
import { Logger } from '~/helpers/logger';
import { ApolloQueryResult } from '@apollo/client/core/types';

export type ApiClientMethod = (...args: any) => Promise<any>;

interface CreateProxiedApiParams {
  nuxtCtx: NuxtContext;
  givenApi: Record<string, ApiClientMethod>;
  client: any;
  tag: string;
  setCookieValues: (cookieValues: Record<string, string>, cookieString: string) => void;
}

export const hasOnlyGraphqlAuthorizationError = (res: ApolloQueryResult<unknown>) => res?.errors
  ?.every((error) => error?.extensions?.category === 'graphql-authorization') ?? false;

export const createProxiedApi = ({
  givenApi, client, tag, nuxtCtx, setCookieValues
}: CreateProxiedApiParams) => new Proxy(givenApi, {
  get: (target, prop, receiver) => {
    const functionName = String(prop);
    if (Reflect.has(target, functionName)) {
      return Reflect.get(target, prop, receiver);
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    const call = async (args) => {
      return client
        .post(`/${tag}/${functionName}`, args)
        .then((r) => r.data)
        .catch((err) => {
          Logger.debug(err);
          nuxtCtx.error({ statusCode: err.statusCode, message: err });

          return {};
        });
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    return async (...args) => {
      let result = await call(args);
      if (hasOnlyGraphqlAuthorizationError(result) && nuxtCtx.app.$vsf.$magento.config.state.getCustomerToken()) {
        if (typeof window === 'undefined') {
          // if running on server need to update cookie in client
          client.defaults.headers.cookie = setCookieValues({
            [nuxtCtx.app.$vsf.$magento.config.cookies.customerCookieName]: nuxtCtx.app.$vsf.$magento.config.state.getCustomerToken()
          }, (client.defaults.headers as any).cookie);
        }
        // if user is remembered and token was regenerated then redo the api call
        result = await call(args);
      }
      return result;
    }
  },
});

export const getCookies = (context: NuxtContext) => context?.req?.headers?.cookie ?? '';

export const getIntegrationConfig = (context: NuxtContext, configuration: any) => {
  const cookie = getCookies(context);
  return merge({
    axios: {
      headers: {
        ...(cookie ? { cookie } : {}),
      },
      withCredentials: true,
    },
  }, configuration);
};

import { defineStore } from 'pinia';
import { NuxtCookies } from 'cookie-universal-nuxt';

const COOKIE_NAME = 'vsf-remember-me';

interface RememberMeState {
  email: string | null,
  token: string | null,
  loaded: boolean
}

export const useRememberMeStore = defineStore('remember-me', {
  state: (): RememberMeState => ({
    email: null,
    token: null,
    loaded: false
  }),
  actions: {
    load($cookies: NuxtCookies) {
      try {
        const data = $cookies.get(COOKIE_NAME);
        if (typeof data === 'object') {
          this.email = data.email || null;
          this.token = data.token || null;
          return;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loaded = true;
      }
      this.email = null;
      this.token = null;
    },
    set($cookies: NuxtCookies, email: string, token: string) {
      this.email = email;
      this.token = token;
      try {
        $cookies.set(COOKIE_NAME, JSON.stringify({
          email: this.email,
          token: this.token,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    clear($cookies: NuxtCookies) {
      this.email = null;
      this.token = null;
      $cookies.remove(COOKIE_NAME);
    }
  },
});

import { defineStore } from 'pinia';

interface AlgoliaState {
  algoliaEnabled: boolean,
}

export const useAlgoliaStore = defineStore('algolia', {
  state: (): AlgoliaState => ({
    algoliaEnabled: false,
  }),
});

import { UseContextReturn } from '~/types/core';
import removeProductsFromGuestWishlist from '~/modules/wishlist/composables/useGuestWishlist/mutations/removeProductsFromGuestWishlist.gql';
import type { RemoveProductsFromGuestWishlistMutationVariables, RemoveProductsFromGuestWishlistOutput } from '~/modules/GraphQL/types';

export const removeProductsFromGuestWishlistCommand = {
  execute: async (context: UseContextReturn, input: RemoveProductsFromGuestWishlistMutationVariables): Promise<RemoveProductsFromGuestWishlistOutput | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.customMutation({ mutation: removeProductsFromGuestWishlist, mutationVariables: input });

    if (errors && errors.length > 0) {
      throw new Error(errors.map(error => error.message).join(' '));
    }
    return (data as any)?.removeProductsFromGuestWishlist ?? null;
  },
};

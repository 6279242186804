import { UseContextReturn } from '~/types/core';
import getAlgoliaConfig from '~/modules/algolia/queries/getAlgoliaConfig.gql';

export const getAlgoliaConfigCommand = {
  execute: async (context: UseContextReturn) => {
    const { data } = await context.app.$vsf.$magento.api.customQuery({ query: getAlgoliaConfig });

    return (data as any)?.algolia_config ?? null;
  },
};

export default function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }

  // if (to.hash) { // pdp configurable url has #
  //   return { selector: to.hash };
  // }

  const scrollRestore = from.meta?.scrollRestore && to.meta?.scrollRestore;
  if (scrollRestore) {
    const prevPosition = localStorage.getItem('last-scroll-position');
    if (prevPosition) {
      return { x: 0, y: Number.parseInt(prevPosition, 10) };
    }
  }

  return { x: 0, y: 0 };
}

import { Aggregation, AggregationOption } from '~/modules/GraphQL/types';

const optionLabelToValueRegex = /[^A-Za-z0-9\-:.]/g;

const _atob = (data) => {
  return Buffer.from(data, 'base64').toString('ascii');
};

function prepareBunksData(options) {
  const rangeData = {
    'low': {
      label: 'Low Bunks < 62”',
      count: 0,
      values: [],
    },
    'mid': {
      label: 'Mid Bunks 62 to 69”',
      count: 0,
      values: [],
    },
    'high': {
      label: 'High Bunks > 69”',
      count: 0,
      values: [],
    },
  }
  options = options.filter(o => o.value !== 0 && o.value !== '0');
  options.forEach(item => {
    if (parseInt(item.label) >= 0 && parseInt(item.label) < 62) {
      rangeData['low'].values.push(item.value);
      rangeData['low'].count += item.count;
    } else if (parseInt(item.label) >= 62 && parseInt(item.label) < 69) {
      rangeData['mid'].values.push(item.value);
      rangeData['mid'].count += item.count;
    } else if (parseInt(item.label) >= 69) {
      rangeData['high'].values.push(item.value);
      rangeData['high'].count += item.count;
    }
  });
  return Object.values(rangeData).filter(({ values }) => values.length > 0).map(({ label, count, values }) => ({
    label: label,
    count: count,
    value: values.join('_'),
  }));
}

function prepareLoftsData(options) {
  const rangeData = {
    'low': {
      label: 'Low Lofts 42 to 60”',
      count: 0,
      values: [],
    },
    'mid': {
      label: 'Mid Lofts 60 to 67”',
      count: 0,
      values: [],
    },
    'high': {
      label: 'High Lofts 67 to 72”',
      count: 0,
      values: [],
    },
    'ultra': {
      label: 'Ultra Lofts 72 to 78”',
      count: 0,
      values: [],
    },
    'uber': {
      label: 'Uber Lofts 88”',
      count: 0,
      values: [],
    }
  };
  options = options.filter(o => o.value !== 0 && o.value !== '0');
  options.forEach(item => {
    if (parseInt(item.label) >= 42 && parseInt(item.label) < 60) {
      rangeData['low'].values.push(item.value);
      rangeData['low'].count += item.count;
    } else if (parseInt(item.label) >= 60 && parseInt(item.label) < 67) {
      rangeData['mid'].values.push(item.value);
      rangeData['mid'].count += item.count;
    } else if (parseInt(item.label) >= 67 && parseInt(item.label) < 72) {
      rangeData['high'].values.push(item.value);
      rangeData['high'].count += item.count;
    } else if (parseInt(item.label) >= 72 && parseInt(item.label) <= 78) {
      rangeData['ultra'].values.push(item.value);
      rangeData['ultra'].count += item.count;
    } else if (parseInt(item.label) >= 88) {
      rangeData['uber'].values.push(item.value);
      rangeData['uber'].count += item.count;
    }
  });
  return Object.values(rangeData).filter(({ values }) => values.length > 0).map(({ label, count, values }) => ({
    label: label,
    count: count,
    value: values.join('_'),
  }));
}

function prepareUniversalData(options) {
  const divider = 3;
  const rangeData = {};
  let k = 0;
  options = options.filter(o => o.value !== 0 && o.value !== '0');
  options.forEach((item, i) => {
    if (i === 0 || i % divider === 0) {
      rangeData[k] = {
        label: i === options.length - 1 ? item.label : `From ${item.label}`,
        count: item.count,
        values: [item.value]
      };
    } else {
      rangeData[k].values.push(item.value);
      rangeData[k].count += item.count;
      if ((i + 1) % divider === 0 || i === options.length - 1) {
        rangeData[k].label += ` To ${item.label}`;
        k++;
      }
    }
  });
  return Object.values(rangeData).map(({ label, count, values }) => ({
    label: label,
    count: count,
    value: values.join('_'),
  }));
}

// converts values like '61”', '65"', '66”' to 'From 61” To 66”' (merges by several into one)
function prepareAttHeightOptionsFilter(filter, catId, catParentId) {
  catId = typeof catId === 'string' ? parseInt(catId) : catId;
  catParentId = typeof catParentId === 'string' ? parseInt(catParentId) : catParentId;
  let options;
  if (catId === 38 || catParentId === 38) {
    options = prepareBunksData(filter.options);
  } else if (catId === 20 || catParentId === 20) {
    options = prepareLoftsData(filter.options);
  } else {
    options = prepareUniversalData(filter.options);
  }
  return {
    ...filter,
    options,
  };
}

function getCategoryParentIdFromPath(path) {
  if (!path) {
    return undefined;
  }
  const pathSplit = path.split('/');
  if (pathSplit.length > 1) {
    return pathSplit[pathSplit.length - 2];
  }
  return undefined;
}

function prepareOptions(options: AggregationOption[]) {
  return options.map(option => typeof option.label === 'string' ? {
    ...option,
    value: option.label.replace(optionLabelToValueRegex, ''),
  } : option);
}

// prepares filters after loading
export function prepareFilters(filters: Aggregation[], catUid, catPath): Aggregation[] {
  const catId = _atob(catUid);
  const catParentId = getCategoryParentIdFromPath(catPath);
  const attHeightOptionsFilterIndex = filters.findIndex(filter => filter.attribute_code === 'att_height_options');
  if (attHeightOptionsFilterIndex >= 0) {
    filters[attHeightOptionsFilterIndex] = prepareAttHeightOptionsFilter(filters[attHeightOptionsFilterIndex], catId, catParentId);
  }
  return filters.map(filter => {
    if (['price', 'category_uid', 'att_height_options'].includes(filter.attribute_code)) {
      return filter;
    }
    return {
      ...filter,
      options: prepareOptions(filter.options),
    }
  });
}

// converts values like '10_11_12' to separate values '10', '11', '12'
function prepareAttHeightOptionsInputFilter(options) {
  const result = [];
  options.forEach(value => {
    result.push(...value.split('_'));
  });
  return result;
}

export function convertAttHeightOptionsValueToLabel(value: string, filters: Aggregation[]) {
  const filter = filters.find(f => f.attribute_code === 'att_height_options');
  if (!filter) {
    return '';
  }
  const option = filter.options.find(o => {
    if (o.value === value) {
      return true;
    }
    const vals = value.split('_');
    return o.value.split('_').find(v => vals.includes(v));
  });
  if (!option) {
    return '';
  }
  return option.label;
}

// prepares filters for api request
export function prepareInputFilters(inputFilters) {
  if (inputFilters.att_height_options) {
    return {
      ...inputFilters,
      att_height_options: prepareAttHeightOptionsInputFilter(inputFilters.att_height_options),
    }
  } else {
    return inputFilters;
  }
}

import { UseContextReturn } from '~/types/core';
import guestWishlist from '~/modules/wishlist/composables/useGuestWishlist/queries/guestWishlist.gql';
import type { GuestWishlist } from '~/modules/GraphQL/types';
import type { UseGuestWishlistLoadParams } from '~/modules/wishlist/composables/useGuestWishlist';
import { Maybe, Scalars } from '~/modules/GraphQL/types';

export interface GuestWishlistVars {
  guestCustomerId: Scalars['ID'];
  itemsCurrentPage?: Maybe<Scalars['Int']>;
  itemsPageSize?: Maybe<Scalars['Int']>;
}

export const guestWishlistCommand = {
  execute: async (context: UseContextReturn, queryVariables: GuestWishlistVars): Promise<GuestWishlist | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.customQuery({ query: guestWishlist, queryVariables });

    if (errors && errors.length > 0) {
      throw new Error(errors.map(error => error.message).join(' '));
    }
    return (data as any)?.guestWishlist ?? null;
  },
};








import { ref, defineComponent, useFetch } from '@nuxtjs/composition-api';
import type { CategoryTree } from '~/modules/GraphQL/types';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';

export default defineComponent({
  components: {
    HeaderNavigation,
  },
  setup() {
    const { categories: categoryList, load: categoriesListLoad } = useCategory();
    const { additionalItems } = useCategoryStore();
    const categoryTree = ref<CategoryTree[]>([]);

    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = [
        ...categoryList.value?.[0]?.children.filter((category) => category.include_in_menu),
        ...additionalItems,
      ];
    });

    return {
      categoryTree,
    };
  },
});

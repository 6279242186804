import { defineStore } from 'pinia';
import type { GuestWishlist } from '~/modules/GraphQL/types';

interface GuestWishlistState {
  wishlist: GuestWishlist,
}

export const useGuestWishlistStore = defineStore('guest-wishlist', {
  state: (): GuestWishlistState => ({
    wishlist: { items_count: 0 },
  }),
});

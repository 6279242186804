import { PinterestOptions, Customer } from '@/modules/GraphQL/types';

/**
 * @param config {PinterestOptions}
 * @param customer {Customer}
 */
export default function (config, customer) {
  if (!config || !config.is_user_connected || !config.is_conversion_config_enabled || typeof document === 'undefined') {
    return;
  }

  !function(e){if(!window.pintrk){window.pintrk=function(){window.pintrk.queue.push(
    Array.prototype.slice.call(arguments))};var
    n=window.pintrk;n.queue=[],n.version="3.0";var
    t=document.createElement("script");t.async=!0,t.src=e;var
    r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
  window.pintrk.partnerData={"np":"adobe"};

  if (config.is_ldp_enabled) {
    const state = customer?.addresses?.[0]?.region_id || customer?.addresses?.[0]?.region || '';
    const country = customer?.addresses?.[0]?.country_code || '';
    pintrk('set', {
      opt_out_type: 'LDP',
      st: state,
      country: country,
    });
  }

  pintrk('setconsent', function() {
    const getCookie = function(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }
    if (!config.is_gdpr_enabled) {
      return true;
    } else if (config.is_cookie_restriction_mode_enabled && config.gdpr_option === 1) {
      const cookieValue = getCookie(config.gdpr_cookie_name);
      if (cookieValue) {
        let gdprCookie = unescape(cookieValue);
        if (gdprCookie) {
          let gdprCookieObj = JSON.parse(gdprCookie);
          return gdprCookieObj[config.current_website_id] === 1;
        }
      }
      return false;
    } else if (config.gdpr_option === 2) {
      return getCookie(config.gdpr_cookie_name) === undefined || getCookie(config.gdpr_cookie_name) === null;
    }
    return true;
  }());
  const hashedEmailId = customer?.email;
  pintrk("load", config.tag_id, hashedEmailId ? {
    "em": hashedEmailId
  } : {});
  pintrk("page");
};

export function trackPage () {
  if (typeof pintrk === 'function') {
    pintrk('page');
  }
}

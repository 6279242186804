








import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { NuxtError } from '@nuxt/types';
import NotFound from '~/components/General/NotFound.vue';
import Error from '~/components/General/Error.vue';

export default defineComponent({
  components: {
    NotFound,
    Error,
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
});














import { defineComponent } from '@nuxtjs/composition-api';
import HeaderBanner from '~/components/HeaderBanner.vue';

export default defineComponent({
  components: {
    HeaderBanner,
  },
  setup() {
    const header = {
      title: '404',
      description: 'This page does not exist, or has been deleted.',
      buttonText: 'Main page',
      link: '/',
      image: '/general/404.jpeg',
      sizes: {
        desktop: [1280, 720],
        mobile: [640, 360],
      },
    };

    return {
      header,
    };
  },
});

import { UseRememberMeErrors, UseRememberMeInterface } from '~/modules/customer/composables/useRememberMe/useRememberMe';
import { useRememberMeStore } from '~/modules/customer/stores/rememberMe';
import { ref, Ref, computed, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { generateRememberMeTokenCommand } from '~/modules/customer/composables/useRememberMe/commands/generateRememberMeTokenCommand';
import { revokeRememberMeTokenCommand } from '~/modules/customer/composables/useRememberMe/commands/revokeRememberMeToken.gql';
import { generateCustomerTokenByRememberMeTokenCommand } from '~/modules/customer/composables/useRememberMe/commands/generateCustomerTokenByRememberMeTokenCommand';
import { FetchResult } from '@apollo/client/core';
import type { GenerateCustomerTokenByRememberMeToken } from '~/modules/GraphQL/types';
import { NuxtAppOptions } from '@nuxt/types/app';

export function useRememberMe(params: { app?: NuxtAppOptions } = {}): UseRememberMeInterface {
  const app = params?.app || useContext().app;
  const store = useRememberMeStore();
  const loading: Ref<boolean> = ref(false);
  const error = ref<UseRememberMeErrors>({
    rememberMe: null,
    login: null,
    clear: null,
  });

  if (!store.loaded) {
    store.load(app.$cookies);
  }

  const rememberMe = async (email: string): Promise<void> => {
    Logger.debug('[Magento] useRememberMe.rememberMe');

    try {
      loading.value = true;
      const apiState = app.$vsf.$magento.config.state;

      if (!apiState.getCustomerToken()) {
        return null;
      }
      const token = await generateRememberMeTokenCommand.execute(app.$vsf);
      if (typeof token === 'string') {
        store.set(app.$cookies, email, token);
      }
      error.value.rememberMe = null;
    } catch (err) {
      error.value.rememberMe = err;
      Logger.error('useRememberMe/rememberMe', err);
    } finally {
      loading.value = false;
    }
  };

  const generateCustomerToken = async (): Promise<FetchResult<GenerateCustomerTokenByRememberMeToken> | null> => {
    Logger.debug('[Magento] useRememberMe.generateCustomerToken');

    let result: FetchResult<GenerateCustomerTokenByRememberMeToken> = null;
    try {
      // TODO: add support for recaptcha
      result = await generateCustomerTokenByRememberMeTokenCommand.execute(app.$vsf, {
        token: store.token
      });
    } catch (err) {
      error.value.login = err;
      Logger.error('useRememberMe/generateCustomerToken', err);
    } finally {
      loading.value = false;
    }

    if (!result?.data?.generateCustomerTokenByRememberMeToken?.token && !result?.errors.find(e => e.message.includes('ReCaptcha'))) {
      await clear();
    }

    return result;
  };

  const clear = async (): Promise<void> => {
    Logger.debug('[Magento] useRememberMe.clear');

    try {
      loading.value = true;

      if (store.token) {
        await revokeRememberMeTokenCommand.execute(app.$vsf, store.token);
      }
    } catch (err) {
      error.value.clear = err;
      Logger.error('useRememberMe/clear', err);
    } finally {
      loading.value = false;
      store.clear(app.$cookies);
    }
  };

  const clearLocal = (): void => {
    store.clear(app.$cookies);
  };

  const checkLogin = (user) => {
    return store.token && store.token === user.password && store.email === user.email;
  }

  return {
    rememberMe,
    generateCustomerToken,
    clear,
    clearLocal,
    checkLogin,
    loading,
    error,
    email: computed(() => store.email),
    token: computed(() => store.token),
    valid: computed(() => !!(store.token && store.email)),
  }
}

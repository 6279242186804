/** GraphQL Query that fetches Attributes Metadata from the API */
export const AttributesQuery = `
  query customAttributeMetadata {
    customAttributeMetadata(
      attributes: [
        {
          attribute_code: "att_quick_ship"
          entity_type: "catalog_product"
        }
        {
          attribute_code: "b_collection_name"
          entity_type: "catalog_product"
        }
      ]
    ) {
      items {
        attribute_code
        attribute_type
        entity_type
        input_type
        attribute_options {
         value
         label
       }
      }
    }
  }
`;

export default AttributesQuery;

import {
  computed, readonly, ref, useContext,
} from '@nuxtjs/composition-api';
import AttributesGql from '~/plugins/query/Attributes.gql';
import type { CustomAttributeMetadata } from '~/modules/GraphQL/types';
import { Logger } from '~/helpers/logger';
import { useAttributesStore } from '~/stores/attributes';
import type { UseAttributesErrors, UseAttributesInterface } from './useAttributes';

/**
 * Allows interacting with the customAttributeMetadata.
 */
export function useAttributes(): UseAttributesInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseAttributesErrors>({ load: null });
  const attributesStore = useAttributesStore();

  const attributes = computed(() => attributesStore.customAttributeMetadata?.items);

  const load = async () => {
    error.value.load = null;
    loading.value = true;

    Logger.debug('useAttributes/load');

    try {
      const { data }: { data: { customAttributeMetadata?: CustomAttributeMetadata } } = await app.$vsf.$magento.api.customQuery({ query: AttributesGql });
      attributesStore.$patch((state) => {
        state.customAttributeMetadata = data.customAttributeMetadata || {};
      });
    } catch (err) {
      Logger.debug('[ERROR] useAttributes/load', err);
      error.value.load = err;
    } finally {
      loading.value = false;
    }
  };

  const getAttributeLabel = (code: string, id: number | string) => {
    const attribute = attributes.value.find((a) => a.attribute_code === code);
    return attribute.attribute_options.find((o) => o.value === String(id))?.label || '';
  };

  return {
    attributes,
    getAttributeLabel,
    loading: readonly(loading),
    load,
  };
}

export * from './useAttributes';
export default useAttributes;

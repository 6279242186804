




























import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute, defineComponent, onMounted, watchEffect, toRefs, useFetch, ref, computed, useRouter, onBeforeUnmount,
} from '@nuxtjs/composition-api';
import { PinterestOptions, useUiState } from '~/composables';
import MegaMenu from '~/components/MegaMenu.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import TopBar from '~/components/TopBar/TopBar.vue';
import MobileTopBar from '~/components/TopBar/MobileTopBar.vue';
import Copyright from '~/components/Copyright.vue';

/* Head External Scripts */
import Hotjar from 'vue-hotjar';
import Vue from 'vue';

import { useAlgolia } from '~/modules/algolia/composables/useAlgolia';
import { useAlgoliaStore } from '~/modules/algolia/stores/algolia';
import { usePinterest } from '~/modules/pinterest';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    LoadWhenVisible,
    LazyHydrate,
    MegaMenu,
    IconSprite,
    TopBar,
    MobileTopBar,
    Copyright,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CovModal: () => import(/* webpackPrefetch: true */ '~/components/TopBar/CovModal.vue'),
    ShippingModal: () => import(/* webpackPrefetch: true */ '~/components/Home/ShippingModal.vue'),
    MobileMenu: () => import(/* webpackPrefetch: true */ '~/components/TopBar/MobileMenu.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const {
      isCovModalOpen, isShippingModalOpen, isMobileMenuOpen, isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen,
      toggleLoginModal,
    } = useUiState();
    const { getConfig: getAlgoliaConfig } = useAlgolia();
    const { algoliaEnabled } = toRefs(useAlgoliaStore());
    const { loadOptions: loadPinterestOptions, parseMetaTag, executeScript } = usePinterest();

    watchEffect(() => {
      (async () => {
        const config = await getAlgoliaConfig();
        console.log('Algolia Config', config);
        algoliaEnabled.value = !!(config && config.enabled && config.api_key && config.application_id && config.index_name);
      })();
    });

    const pinterestScriptExecuted = ref<boolean>(false);
    const pinterestOptions = ref<PinterestOptions | null>(null);
    useFetch(async () => {
      pinterestOptions.value = await loadPinterestOptions();
      if (typeof window !== 'undefined') {
        initPinterest();
      }
    });

    const pinterestMetaTag = computed(() => {
      return pinterestOptions.value ? parseMetaTag(pinterestOptions.value.meta_tag) : null;
    });

    const initHotjar = () => {
      /* Init Hotjar - Only in Production Mode */
      Vue.use(Hotjar, {
        id: '1574073', // Hotjar Site ID
        isProduction: false, // process.env.NODE_ENV === 'production'
      });
    };

    const initLandbot = () => {
      /* Init Landbot - Both Modes  */
      if (!(window as any).Landbot) return;
      // @ts-ignore
      new Landbot.Livechat({
        configUrl: 'https://storage.googleapis.com/landbot.online/v3/H-1620353-UEVP1POAP4NC17KW/index.json',
      });
    };

    const initPinterest = () => {
      if (pinterestScriptExecuted.value || !pinterestOptions.value) {
        return;
      }
      executeScript(pinterestOptions.value);
      pinterestScriptExecuted.value = true;
    };

    onMounted(() => {
      initHotjar();
      initLandbot();
      initPinterest();

      localStorage.removeItem('last-scroll-position');
    });

    router.beforeEach((to, from, next) => {
      localStorage.setItem('last-scroll-position', `${window.scrollY || document.documentElement.scrollTop}`);
      next();
    });

    return {
      isCovModalOpen,
      isShippingModalOpen,
      isMobileMenuOpen,
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
      pinterestOptions,
      pinterestMetaTag,
    };
  },

  head () {
    const meta = [];
    if (this.pinterestMetaTag) {
      meta.push(this.pinterestMetaTag);
    }
    return {
      link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
      meta,
    };
  },
});

import { UseContextReturn } from '~/types/core';
import guestWishlistItemsCount from '~/modules/wishlist/composables/useGuestWishlist/queries/guestWishlistItemsCount.gql';
import type { GuestWishlist } from '~/modules/GraphQL/types';

export const guestWishlistItemsCountCommand = {
  execute: async (context: UseContextReturn, guestCustomerId: string): Promise<GuestWishlist | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.customQuery({ query: guestWishlistItemsCount, queryVariables: { guestCustomerId } });

    if (errors && errors.length > 0) {
      throw new Error(errors.map(error => error.message).join(' '));
    }
    return (data as any)?.guestWishlist ?? null;
  },
};

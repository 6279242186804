// "categoryList" query-override doesn't work from middleware.config.js
export default `
  query categoryList {
    categories {
      items {
        children {
          include_in_menu
          is_anchor
          level
          name
          image
          additional_home_image
          meta_description
          short_description
          position
          product_count
          uid
          url_path
          url_suffix
          children {
            include_in_menu
            is_anchor
            level
            name
            image
            additional_home_image
            meta_description
            short_description
            position
            product_count
            uid
            url_path
            url_suffix
            children {
              include_in_menu
              is_anchor
              level
              name
              image
              additional_home_image
              meta_description
              short_description
              position
              product_count
              uid
              url_path
              url_suffix
            }
          }
        }
        product_count
        name
        image
        additional_home_image
        meta_description
        uid
      }
    }
  }
`;

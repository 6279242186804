import { UseContextReturn } from '~/types/core';
import sendWishlist from '~/modules/wishlist/composables/useWishlist/queries/sendWishlist.gql';
import type { SendWishlistInput, SendWishlistOutput } from '~/modules/GraphQL/types';

export const sendWishlistCommand = {
  execute: async (context: UseContextReturn, queryVariables: SendWishlistInput): Promise<SendWishlistOutput | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.customQuery({ query: sendWishlist, queryVariables });

    if (errors && errors.length > 0) {
      throw new Error(errors.map(error => error.message).join(' '));
    }
    return (data as any)?.sendWishlist ?? null;
  },
};

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0f57e291 = () => interopDefault(import('../modules/usefulInfo/pages/AboutUs.vue' /* webpackChunkName: "" */))
const _50bec30e = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _31ae1d9a = () => interopDefault(import('../modules/checkout/pages/CheckoutGuest.vue' /* webpackChunkName: "" */))
const _27b9efba = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _68f30ade = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "" */))
const _5210aada = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _1c04bb81 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyInfo/MyInfo.vue' /* webpackChunkName: "" */))
const _6eed0901 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _901d8962 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _36188986 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _4a8c628d = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _62b9487a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _151d24fe = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _7e45df7e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _3c096012 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _1704ffed = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _534a97ce = () => interopDefault(import('../modules/usefulInfo/pages/UsefulInfo.vue' /* webpackChunkName: "" */))
const _395fa2be = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _547f9e61 = () => interopDefault(import('../modules/catalog/pages/products.vue' /* webpackChunkName: "" */))
const _bcf8cb5e = () => interopDefault(import('../pages/Stores.vue' /* webpackChunkName: "" */))
const _ecd68f24 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _d3741466 = () => interopDefault(import('../modules/algolia/pages/Search.vue' /* webpackChunkName: "" */))
const _3aaab0a6 = () => interopDefault(import('../pages/Sitemap.vue' /* webpackChunkName: "pages/Sitemap" */))
const _30f39f24 = () => interopDefault(import('../modules/wishlist/pages/GuestWishlist.vue' /* webpackChunkName: "" */))
const _5a93e830 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _c68cb284 = () => interopDefault(import('../modules/customer/pages/ConfirmCustomer.vue' /* webpackChunkName: "" */))
const _6a041988 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _28fe3a1f = () => interopDefault(import('../modules/socialLogin/pages/Callback.vue' /* webpackChunkName: "" */))
const _00544940 = () => interopDefault(import('../modules/socialLogin/pages/StartAuth.vue' /* webpackChunkName: "" */))
const _078fd573 = () => interopDefault(import('../modules/wishlist/pages/SharedWishlist.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _0f57e291,
    meta: {"title":"About us"},
    name: "about-us___default"
  }, {
    path: "/checkout",
    component: _50bec30e,
    name: "checkout___default"
  }, {
    path: "/checkout-guest",
    component: _31ae1d9a,
    name: "checkout-guest___default"
  }, {
    path: "/Cms",
    component: _27b9efba,
    name: "Cms___default"
  }, {
    path: "/contact",
    component: _68f30ade,
    name: "contact___default"
  }, {
    path: "/Contact",
    component: _68f30ade,
    name: "Contact___default"
  }, {
    path: "/customer",
    component: _5210aada,
    meta: {"title":"My Account"},
    name: "customer___default",
    children: [{
      path: "account",
      component: _1c04bb81,
      meta: {"title":"My Information","scrollRestore":true},
      name: "customer-my-profile___default"
    }, {
      path: "address",
      component: _6eed0901,
      meta: {"title":"Addresses details","scrollRestore":true},
      name: "customer-addresses-details___default"
    }, {
      path: "wishlist",
      component: _901d8962,
      meta: {"title":"My wishlist","scrollRestore":true},
      name: "customer-my-wishlist___default"
    }, {
      path: "account/edit",
      component: _36188986,
      meta: {"title":"Account Information","scrollRestore":true},
      name: "customer-my-profile-edit___default"
    }, {
      path: "address/new",
      component: _4a8c628d,
      meta: {"title":"Addresses details","scrollRestore":true},
      name: "customer-addresses-details-new___default"
    }, {
      path: "newsletter/manage",
      component: _62b9487a,
      meta: {"title":"My newsletter","scrollRestore":true},
      name: "customer-my-newsletter___default"
    }, {
      path: "/review/customer",
      component: _151d24fe,
      meta: {"title":"My reviews","scrollRestore":true},
      name: "customer-my-reviews___default"
    }, {
      path: "sales/order/history",
      component: _7e45df7e,
      meta: {"title":"Order history","scrollRestore":true},
      name: "customer-order-history___default"
    }, {
      path: "sales/order/view/order_id/:orderId",
      component: _3c096012,
      meta: {"title":"Order history","scrollRestore":true},
      props: true,
      name: "customer-single-order___default"
    }, {
      path: "address/edit/id/:addressId",
      component: _1704ffed,
      meta: {"title":"Addresses details","scrollRestore":true},
      props: true,
      name: "customer-addresses-details-edit___default"
    }]
  }, {
    path: "/faq",
    component: _534a97ce,
    meta: {"title":"FAQ","scrollRestore":true},
    name: "faq___default"
  }, {
    path: "/Home",
    component: _395fa2be,
    name: "Home___default"
  }, {
    path: "/made-in-canada",
    component: _547f9e61,
    props: {"title":"Made in Canada","defaultFilters":{"manufacturer":["Canada"]}},
    name: "made-in-canada___default"
  }, {
    path: "/our-stores",
    component: _bcf8cb5e,
    name: "our-stores___default"
  }, {
    path: "/Page",
    component: _ecd68f24,
    name: "Page___default"
  }, {
    path: "/pricing-policy",
    component: _534a97ce,
    meta: {"title":"Pricing Policy","scrollRestore":true},
    name: "pricing-policy___default"
  }, {
    path: "/privacy-policy",
    component: _534a97ce,
    meta: {"title":"Privacy Policy","scrollRestore":true},
    name: "privacy-policy___default"
  }, {
    path: "/quick-ship",
    component: _547f9e61,
    props: {"title":"Quick Ship","defaultFilters":{"att_quick_ship":["Shipswithinaweek"]}},
    name: "quick-ship___default"
  }, {
    path: "/return-policy",
    component: _534a97ce,
    meta: {"title":"Return Policy","scrollRestore":true},
    name: "return-policy___default"
  }, {
    path: "/search",
    component: _d3741466,
    name: "search___default"
  }, {
    path: "/security-policy",
    component: _534a97ce,
    meta: {"title":"Security Policy","scrollRestore":true},
    name: "security-policy___default"
  }, {
    path: "/shipping-info",
    component: _534a97ce,
    meta: {"title":"Shipping Info","scrollRestore":true},
    name: "shipping-info___default"
  }, {
    path: "/Sitemap",
    component: _3aaab0a6,
    name: "Sitemap___default"
  }, {
    path: "/Stores",
    component: _bcf8cb5e,
    name: "Stores___default"
  }, {
    path: "/warranty-info",
    component: _534a97ce,
    meta: {"title":"Warranty Info","scrollRestore":true},
    name: "warranty-info___default"
  }, {
    path: "/wishlist",
    component: _30f39f24,
    name: "guest-wishlist___default"
  }, {
    path: "/checkout/cart",
    component: _5a93e830,
    name: "cart___default"
  }, {
    path: "/customer/account/confirm",
    component: _c68cb284,
    name: "confirm-email___default"
  }, {
    path: "/customer/account/reset-password",
    component: _6a041988,
    alias: "/customer/account/createPassword",
    name: "reset-password___default"
  }, {
    path: "/sociallogin/social/callback",
    component: _28fe3a1f,
    name: "sociallogin-callback___default"
  }, {
    path: "/sociallogin/social/start",
    component: _00544940,
    name: "sociallogin-start-auth___default"
  }, {
    path: "/wishlist/shared/:sharingCode",
    component: _078fd573,
    name: "shared-wishlist___default"
  }, {
    path: "/",
    component: _395fa2be,
    name: "home___default"
  }, {
    path: "/:slug+",
    component: _ecd68f24,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

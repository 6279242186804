import { Middleware } from '@nuxt/types';
import type { UiNotification } from '~/composables/useUiNotification';

const middleware : Middleware = (context) => {
  if (!context.app.$vsf.$magento.config.state.getCustomerToken()) {
    context.app.$vsf.$magento.config.state.setMessage<UiNotification>({
      id: Symbol(''),
      message: context.app.i18n.t('You are not authorized, please log in.') as string,
      type: 'warning',
      icon: null,
      persist: true,
      title: null,
    });
    context.redirect(context.localeRoute({ name: 'home' }));
  }
};
export default middleware;

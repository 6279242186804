import { UseContextReturn } from '~/types/core';
import createGuestWishlist from '~/modules/wishlist/composables/useGuestWishlist/mutations/createGuestWishlist.gql';
import type { GuestWishlist } from '~/modules/GraphQL/types';

export const createGuestWishlistCommand = {
  execute: async (context: UseContextReturn): Promise<GuestWishlist | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.customMutation({ mutation: createGuestWishlist, mutationVariables: {} });

    if (errors && errors.length > 0) {
      throw new Error(errors.map(error => error.message).join(' '));
    }
    return (data as any)?.createGuestWishlist ?? null;
  },
};

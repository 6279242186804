






import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';

export default defineComponent({
  setup() {
    const { config } = useConfig();

    const copyright = computed(() => config.value.copyright || '');

    return {
      copyright,
    };
  },
});


import { UseContextReturn } from '~/types/core';
import mergeWishlistWithGuestWishlist from '~/modules/wishlist/composables/useGuestWishlist/mutations/mergeWishlistWithGuestWishlist.gql';
import type { Wishlist } from '~/modules/GraphQL/types';

export const mergeWishlistWithGuestWishlistCommand = {
  execute: async (context: UseContextReturn, guestCustomerId: string): Promise<Wishlist | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.customMutation({ mutation: mergeWishlistWithGuestWishlist, mutationVariables: { guestCustomerId } });

    if (errors && errors.length > 0) {
      throw new Error(errors.map(error => error.message).join(' '));
    }
    return (data as any)?.mergeWishlistWithGuestWishlist ?? null;
  },
};






























































import { useUiState } from '~/composables';
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { SfTopBar, SfButton, SfOverlay } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import HeaderLogoVector from '~/components/HeaderLogoVector.vue';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import type { ProductInterface } from '~/modules/GraphQL/types';
import { useAlgoliaStore } from '~/modules/algolia/stores/algolia';

export default defineComponent({
  components: {
    SfTopBar,
    SfButton,
    SfOverlay,
    SvgImage,
    HeaderLogoVector,
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchSuggestions: () => import(/* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchSuggestions.vue'),
  },
  setup() {
    const { isMobileMenuOpen, toggleMobileMenu } = useUiState();
    const { algoliaEnabled } = useAlgoliaStore();

    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const searchQuery = ref<string | null>(null);

    const loadCategoryMenu = async () => {
      const categories = useCategoryStore();
      if (categories.categories === null) {
        await categories.load();
      }
      toggleMobileMenu();
    };

    return {
      loadCategoryMenu,
      isMobileMenuOpen,
      isSearchOpen,
      productSearchResults,
      algoliaEnabled,
      searchQuery,
    };
  },
});


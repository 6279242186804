import { defineStore } from 'pinia';
import { CustomAttributeMetadata } from '~/modules/GraphQL/types';

interface ConfigState {
  customAttributeMetadata: CustomAttributeMetadata,
}

export const useAttributesStore = defineStore('attributes', {
  state: (): ConfigState => ({
    customAttributeMetadata: {},
  }),
});
